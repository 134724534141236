import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";

import {
  getAdminOrganization,
  modifyUser,
  deleteUser,
} from "../../../assets/api";

import { AuthContext } from "../../../context/AuthContext";
import { Languages } from "../../../types/service";
import { UserToAdd } from "../../../types/organization";

import Layout from "../../Layout/LayoutContainer";
import Card from "../../Card/Card";
import {
  Button as ButtonSemantic,
  Dimmer,
  Form,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import { Button } from "../../Button/Button";
import { LayoutContext } from "../../../context/LayoutContext";
import { toast } from "react-toastify";
import { UserInfo } from "../../../models/User";

const initializeUser: UserToAdd = {
  showName: true,
  active: true,
  code: "",
  name: "",
  mail: "",
};

const EditUser = (user: any) => {
  const translation = "organitzation.user.edit";
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;

  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [code, setCode] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [checked, setchecked] = useState(false);
  const [visible, setVisible] = useState(true);
  const [form, setForm, handleInputChange, reset] = useForm(initializeUser);

  useEffect(() => {
    setCode(user.location.state.code);
    setname(user.location.state.name);
    setemail(user.location.state.mail);
    setchecked(user.location.state.active);
    setVisible(user.location.state.showName);

    return () => {};
  }, []);

  const arrayButtons = [
    {
      icon: "remove icon",
      message: t(`${translation}.btn.delete`),
      onClick: () => removeUser(),
    },
  ];

  const removeUser = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    await deleteUser(code, backoffice)
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(t(`${translation}.message.delete.error`));
      })
      .then((response) => {
        toast(t(`${translation}.message.delete.success`), { type: "success"});
      })
      .catch((e) => {
        toast((e.message), { type: "error"});
      });
    history.push(
      `/organitzation/${authContext.account.profile.organization}/user`
    );
  };

  useEffect(() => {
    setTitle(t(`${translation}.title`));
    setBreadcrumb([
      {
        name: t("organitzation.panel_org.title"),
        link: "/organitzation",
      },
      {
        name: t("organitzation.user.panel.title"),
        link:
          "/organitzation/" +
          authContext.account.profile.organization +
          "/user",
      },
      {
        name: t(`${translation}.title`),
      },
    ]);

    setLoading(false);
  }, []);

  const historyHandleClick = () => {
    history.push(
      "/organitzation/" + authContext.account.profile.organization + "/user"
    );
  };

  const handleSubmit = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    console.log("pre setForm", visible);

    setForm((form: UserToAdd) => {
      form.active = true;
      form.showName = true;
      return form;
    });

    const userToModify: UserToAdd = {
      showName: visible,
      active: checked,
      code: code,
      mail: email,
      name: name,
    };

    try {
      await modifyUser(code, userToModify, backoffice)
        .then((response) => {
          if (response.ok) return response.json();

          throw new Error(t(`${translation}.message.edit.error`));
        })
        .then((response) => {
          toast((
            t(`${translation}.message.edit.success`) + ` (${response.code})`
          ), { type: "success"});
          historyHandleClick();
        });
    } catch (e) {
      toast((e.message), { type: "error"});
      historyHandleClick();
    }
  };

  return (
    <>
      <Layout.Main>
        {loading ? (
          <Dimmer active inverted size={"massive"}>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : (
          <>
            <Card>
              <Card.Body>
                <Form style={{ boxShadow: "none" }} className="border-0">
                  <Form.Field>
                    <label>{t(`${translation}.form.code`)}</label>
                    <input name="code" disabled value={code} />
                  </Form.Field>
                  <Form.Field>
                    <label>{t(`${translation}.form.name`)}</label>
                    <input
                      name="name"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t(`${translation}.form.email`)}</label>
                    <input
                      name="displayName"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name="active"
                      checked={checked}
                      label={t(`${translation}.form.active`)}
                      onChange={() => setchecked(!checked)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name="name_visible"
                      checked={visible}
                      label={t(`${translation}.form.visible`)}
                      onChange={() => setVisible(!visible)}
                    />
                  </Form.Field>
                  <ButtonSemantic
                    negative
                    content={t("btn.cancel")}
                    className="mt-3"
                    onClick={historyHandleClick}
                  />
                  <ButtonSemantic
                    positive
                    icon="edit"
                    content={t(`${translation}.btn.save`)}
                    className="mt-3"
                    onClick={handleSubmit}
                  />
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
      </Layout.Main>
      <Layout.Article>
        {arrayButtons.map((button) => (
          <Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </Layout.Article>
    </>
  );
};

export default EditUser;
