import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "../../hooks/useForm";

import { Service, Languages } from "../../types/service";
import { AuthContext } from "../../context/AuthContext";

import Layout from "../Layout/LayoutContainer";
import Card from "../Card/Card";
import { Button as ButtonSemantic, Form, Select } from "semantic-ui-react";
import {
  fetchMyAreasAdmin,
  fetchCreateService,
  fetchRelationshipServiceWithArea,
} from "../../assets/api";
import { Button } from "../Button/Button";
import { LayoutContext } from "../../context/LayoutContext";
import { toast } from "react-toastify";

interface ISelect {
  key: string;
  value: string;
  text: string;
}

const defaultLanguages: Languages[] = [
  {
    displayName: "",
    culture: "ca",
  },
  {
    displayName: "",
    culture: "es",
  },
  {
    displayName: "",
    culture: "en",
  },
  {
    displayName: "",
    culture: "oc",
  },
];

const defaultService: Service = {
  active: false,
  areaCode: "",
  code: "",
  displayName: "",
  email: "",
  uri: "",
  recordMeetingEnabled: false,
  languages: defaultLanguages,
  sendPreviousDocumentation: false,
};

const ServicesCreate = () => {
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;

  const { t } = useTranslation();
  const history = useHistory();

  const [form, setForm, handleInputChange, reset] = useForm(defaultService);

  const ca = defaultLanguages.find((lang) => lang.culture === "ca");
  const es = defaultLanguages.find((lang) => lang.culture === "es");
  const en = defaultLanguages.find((lang) => lang.culture === "en");
  const oc = defaultLanguages.find((lang) => lang.culture === "oc");

  const [formLangCA, setFormLangCA, handleInputChangeLangCA, resetLangCA] = useForm(ca);
  const [formLangES, setFormLangES, handleInputChangeLangES, resetLangES] = useForm(es);
  const [formLangEN, setFormLangEN, handleInputChangeLangEN, resetLangEN] = useForm(en);
  const [formLangOC, setFormLangOC, handleInputChangeLangOC, resetLangOC] = useForm(oc);

  const [area, setArea] = useState("");
  const [selectAreas, setSelectAreas] = useState<ISelect[]>([]);

  const arrayButtons = [
    {
      icon: "arrow left",
      message: "go_back",
      onClick: () => history.goBack(),
    },
  ];

  useEffect(() => {
    setTitle(t("create.service.title"));
    setBreadcrumb([
      {
        name: t("area.title"),
        link: "/area",
      },
      {
        name: t("create.service.title"),
      },
    ]);

    retrieveAreas();
  }, []);

  const retrieveAreas = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    await fetchMyAreasAdmin(backoffice)
      .then(async (response) => await response.json())
      .then(async (response) => {
        await response.map((service: Service) => {
          const selectArea: ISelect = {
            key: service.code,
            text: service.displayName,
            value: service.displayName,
          };

          setSelectAreas((select) => [...select, selectArea]);
        });
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const { backoffice } = await authContext.getTokenForScopes();
    const formLanguages: Languages[] = [
      { ...formLangCA },
      { ...formLangES },
      { ...formLangEN },
      { ...formLangOC },
    ];

    setForm((form: Service) => {
      form.active = true;
      form.areaCode = area;
      form.displayName = formLangCA.displayName;
      form.languages = formLanguages;
      return form;
    });

    fetchCreateService(form, backoffice)
      .then(async (response: Response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t("create.message.service.add.error"));
        }
      })
      .then((response) => {
        history.push("/area", { area });
        toast((
          t(`create.message.service.add.success`) + ` (${response.code})`
        ), { type: "success"});
      })
      .catch((e) => {
        toast((e.message), { type: "error"});
      });
  };

  const updateArea = (event: any, data: any) => {
    const result = getOptionSelected(data);

    setArea(result.key);
  };

  const getOptionSelected = (data: any) => {
    const value = data.value;
    const options: ISelect[] = data.options;

    return options.filter((option: ISelect) => option.value === value)[0];
  };

  const getContentFromCulture = (culture: string) => {
    switch (culture) {
      case "ca":
        return formLangCA.displayName;
      case "es":
        return formLangES.displayName;
      case "en":
        return formLangEN.displayName;
      case "oc":
        return formLangOC.displayName;
      default:
        return;
    }
  };

  const getOnChangeFromCulture = (culture: string, e: any) => {
    switch (culture) {
      case "ca":
        return handleInputChangeLangCA(e);
      case "es":
        return handleInputChangeLangES(e);
      case "en":
          return handleInputChangeLangEN(e);
      case "oc":
        return handleInputChangeLangOC(e);
      default:
        return;
    }
  };

  const formatString = (value: string) => {
    return value.replace(/[^a-zA-Z0-9]/g, "");
  };

  const onChangeInputCode = ({ target }: any) => {
    target.value = formatString(target.value);
    handleInputChange({ target });
  };

  return (
    <>
      <Layout.Main>
        <Card>
          <Card.Body>
            <Form
              onSubmit={handleSubmit}
              className="border-0"
              style={{ boxShadow: "none" }}
            >
              <Form.Field>
                <label>{t("create.service.code")}</label>
                <input
                  name="code"
                  value={form.code}
                  onChange={onChangeInputCode}
                ></input>
              </Form.Field>
              {defaultLanguages.map((language: Languages, index: number) => (
                <Form.Field key={index}>
                  <label>
                    {t("create.service.name")} ({language.culture})
                  </label>
                  <input
                    name="displayName"
                    autoComplete="off"
                    value={getContentFromCulture(language.culture)}
                    onChange={(e) =>
                      getOnChangeFromCulture(language.culture, e)
                    }
                  />
                </Form.Field>
              ))}
              <Form.Field>
                <label>{t("create.service.area")}</label>
                <Select
                  options={selectAreas}
                  onChange={(event, data) => updateArea(event, data)}
                  required
                />
              </Form.Field>
              <ButtonSemantic
                type={"button"}
                onClick={() => history.goBack()}
                negative
                content={t("go_back")}
                icon="arrow left"
              />
              <ButtonSemantic
                type="submit"
                positive
                content={t("create.service.create")}
                icon="checkmark"
              />
            </Form>
          </Card.Body>
        </Card>
      </Layout.Main>
    </>
  );
};

export default ServicesCreate;
