import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../context/AuthContext";
import { LayoutContext } from "../../context/LayoutContext";

import Layout from "../Layout/LayoutContainer";
import { Button } from "../Button/Button";

import { downloadRecordingsCSV, getAreaInfo, getRecordingDetails } from "../../assets/api";

import { toast } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import InputRecording from "../InputRecording";
import DropBoxContainer from "./components/DropBoxContainer";
import DropDownAreas from "../DropDownAreas";
import DropDownServices from "../DropDownServices";

import Table from "./components/Table";
import DropDownOrganization from "../DropDownOrganization";
import { defaultRecordingsFormatted, Recordings, RecordingsFormatted } from "./types";
import { formatRecording, formatRecordingData } from "./format/recording";
import { getAreasFromOrganization } from "../../assets/personalitzation";
import { Area, Service } from "../../types/area";
import { defaultAreaInfo } from "../../models/AreaInfo";
import { fetchGetAreasServicesApi, getAreasServicesApi } from "../../assets/areas";
import { dispatch } from "rxjs/internal/observable/pairs";
import { useDispatch } from "react-redux";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { getReasonCodesMap } from "../../models/ReasonCodesMap";

const RecordingDetails = (props: any) => {
  const authContext = useContext(AuthContext);
  const { setTitle, setBreadcrumb } = useContext(LayoutContext);

  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [tokenContinuation, setTokenContinuation] = useState<any>("");
  const [backTokenList, setBackTokenList] = useState<any>({});
  const [maxItemsPerRow] = useState<number>(10);
  const [recordingsFormatted, setRecordingsFormatted] = useState<RecordingsFormatted[]>([defaultRecordingsFormatted]);
  const [form, setForm] = useState<any>({dateStart: "", dateEnd: "", organizations: "", area: "", service: ""});

  const areaCode = props.match?.params?.areaCode;
  const initialHistory = props.match?.path?.split("/")[1];
  const initialHistoryPath = "/" + initialHistory;

  const historyHandleClick = () => {
    history.push(initialHistoryPath);
  };

  const [buttons, setButtons] = useState([
    {
      icon: "arrow left",
      message: "go_back",
      onClick: historyHandleClick,
      disabled: false
    },
  ]);

  useEffect(() => {
    setTitle(t("recordings.title"));
    setBreadcrumb([
      {
        name: t(`breadcrump.${initialHistory}`),
        link: initialHistory,
      },
      {
        name: t("recordings.title"),
      },
    ]);
    
    const initialOrganization = getOrganization();

    if(!initialOrganization){
      getServices(areaCode);
    } else {
      if(!areaCode){
        getAreas(initialOrganization);
        areas.map(area => {
          getServices(area.code);
        })
      }else{
        getServices(areaCode);
      }
    }

    setLoading(true);
  }, []);

  useEffect(() => {
    if(loading){

      if(organizations.length === 0){
        setAreas([]);
        setServices([]);
      }
      else
        organizations.map(organization => {
          getAreas(organization);
        })
    }
  }, [organizations])

  const getOrganization = () => {
    if (areaCode) return authContext.account.profile.organization;
    else return props.match?.params?.organitzationCode;
  };

  const getAreas = async (organizationInitial: string) => {
    const { backoffice } = await authContext.getTokenForScopes();

    getAreasFromOrganization(organizationInitial, backoffice)
      .then(response => response.json())
      .then((response: Area[]) => setAreas(response))
  }

  const getServices = async(area: string) => {
    const { backoffice } = await authContext.getTokenForScopes();
    if(area === "") {
      setForm({...form, ["service"]: ""})
    }
    else {
      getAreasServicesApi(area, backoffice)
        .then(response => setServices(response))
      if (areaCode !== undefined) setForm({...form, ["area"]: areaCode})
    }
  }

  const formIsValidate = () => {
    if (form.dateStart === "" || form.dateEnd === "") {
      toast(t("recordings.no_date"), { type: "error"});
      return false;
    }

    if (form.dateStart > form.dateEnd === true) {
      toast(t("recordings.incorrect_date"), { type: "error"});
      return false;
    }
    if ((!form.organizations && !getOrganization()) || (!getOrganization() && form.organizations.length === 0)) {
      toast(t("recordings.no_organizations"), { type: "error"});
      return false;
    }

    return true;
  }

  const getToken = (token: any, isForward: boolean) => {
    if (!isForward) {
      try {
        token = backTokenList[backTokenList[token]];
      } catch (error) {
        token = "";
      }
    }
    return token.length > 0 ? JSON.stringify(token).replace(/\"/g, "") : "";
  };

  const getRecordings = async(isForward: boolean, useContinuationToken: boolean) => {
    if(formIsValidate()){
      const { backoffice } = await authContext.getTokenForScopes();
      let token;

      if(useContinuationToken)
        token = getToken(tokenContinuation, isForward);
      else
        token = getToken("", isForward);

      const { key, value } = await getRecordingDetails(
        form,
        maxItemsPerRow,
        token,
        backoffice
      ).then((response) => {
        console.log("RESPONSE", response);
        if (response.status == "204") {
          return {key:"", value:[]}
        }
        if (response.status == "400") {
          return {key:"", value:[]}
        }
        return response.json();
      });

      if(value.length === 0) {
        toast(t("recordings.error_data_not_found"), { type: "info"});
      }
      else{
        const recordings: Recordings[] = value; 
        console.log("REC 0", recordings[0])
  
        setBackTokenList({ ...backTokenList, [key]: token });
        setTokenContinuation(key);
  
        console.log("RECORDINGS", recordings);
        console.log("format rec", formatRecordingData);
        const recordingsFormattedResult: RecordingsFormatted[] = recordings.map(formatRecordingData);
        console.log("recordingsFormattedResult", recordingsFormattedResult);
        setRecordingsFormatted(recordingsFormattedResult);
  
        setButtons([
          {
            icon: "arrow left",
            message: "go_back",
            onClick: historyHandleClick,
            disabled:false
          },
          {
            icon: "file",
            message: "recordings.csv",
            onClick: generateCSV,
            disabled:false
          },
        ]);
  
        return recordingsFormattedResult;
      }
    }
  }
  
  const generateCSV = async () => {
    setButtons([
      {
        icon: "arrow left",
        message: "go_back",
        onClick: historyHandleClick,
        disabled:false
      },
      {
        icon: "file",
        message: "recordings.csv",
        onClick: generateCSV,
        disabled:true
      },
    ]);
    const { backoffice } = await authContext.getTokenForScopes();
    const token = getToken("", true);

    const { value } = await getRecordingDetails(
      form,
      1000,
      token,
      backoffice
    ).then((response) => {
      if (response.status === "204") {
        toast(t("recordings.error_data_not_found"), { type: "info"});
      }
      return response.json();
    });

    const recordings: Recordings[] = value;
    console.log("AQUIII1: ", value);



    const recordingsFormattedResult: RecordingsFormatted[] = recordings.map(formatRecordingData);
    const result = recordingsFormattedResult.map(recording => formatRecording(recording, t));
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Recording Details",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "RecordingDetails",
    };
    
    const reasonCodesMap = getReasonCodesMap(t);
    result.forEach(item => {
        item.Motiu = reasonCodesMap[item.Motiu];
    });

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(result);
    setButtons([
      {
        icon: "arrow left",
        message: "go_back",
        onClick: historyHandleClick,
        disabled:false
      },
      {
        icon: "file",
        message: "recordings.csv",
        onClick: generateCSV,
        disabled:false
      },
    ]);
  }
  
  return (
    <>
      <Layout.Main>
        <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
          <div className="w-100 mr-4">
            <div className="d-flex justify-content-between">
              <InputRecording
                  header={t("recordings.start")}
                  classNameContainer="w-50 mr-2"
                  name={"dateStart"}
                  inputType={"date"}
                  onChange={(value: any, accessor: string) => {
                    setForm({ ...form, [accessor]: value });
                    setTokenContinuation("");
                  }}
                />

              <InputRecording
                header={t("recordings.end")}
                classNameContainer="w-50"
                name="dateEnd"
                inputType={"date"}
                onChange={(value: any, accessor: string) => {
                  setForm({ ...form, [accessor]: value });
                  setTokenContinuation("");
                }}
              />

            </div>
            {!getOrganization() && (
              <DropDownOrganization
                header={t("recordings.organization")}
                styleContainer={{ width: "45%" }}
                onChange={(organizations: string[]) => {          
                  setOrganizations(organizations);
                  setForm({...form, ["organizations"]: organizations})
                }}
              />
            )}
            <DropBoxContainer header={t("recordings.more_filters")}>
              <div className="d-flex flex-wrap">
                {!areaCode && (
                  <DropDownAreas
                    header={t("recordings.area")}
                    areas={areas}
                    styleContainer={{ width: "45%" }}
                    classNameContainer="mr-4"
                    onChange={(area) => {
                      console.log("AQUI:", areas);
                      if (area !== "") {
                        getServices(area);
                      }
                      else {
                        setServices([]);
                      }
                      setForm({...form, ["area"]: area, ["service"]: ""})
                    }}
                  />
                )}

                <DropDownServices 
                  header={t("service")}
                  services={services}
                  styleContainer={{ width: "45%" }}
                  onChange={(service) => {
                    setForm({...form, ["service"]: service})
                  }}
                />
              </div>
            </DropBoxContainer>
          </div>
          <button
            className = "btn btn-danger mt-4"
            style = {{ height: 50 }}
            onClick = {async () => {
              await setRecordingsFormatted([defaultRecordingsFormatted]);
              setTokenContinuation("");
              getRecordings(true, false)
            }}
          >
            {t("recordings.search")}
          </button>
        </div>
        {recordingsFormatted[0].meetingID.length !== 0 && (
          <>
            <div className="d-flex justify-content-end">
              {backTokenList[tokenContinuation] && (
                <button
                  className="btn btn-danger mt-4 mr-2"
                  onClick={() => getRecordings(false, true)}
                >
                  {t("recordings.previous")}
                </button>
              )}
              {tokenContinuation && (
                <button
                  className="btn btn-danger mt-4"
                  onClick={() => getRecordings(true, true)}
                >
                  {t("recordings.next")}
                </button>
              )}
            </div>
            <div className="table-responsive mt-5 px-3">
              <Table
                data={recordingsFormatted}
                translationHeader={(text: string) =>  t(`recordings.${text}`)}
              />
            </div>
          </>
        )}
      </Layout.Main>
      
      <Layout.Article>
        {buttons.map((button) => (
          <><Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
            disabled={button.disabled} />
            {button.disabled && <Segment padded={"very"}>
              <Dimmer active inverted size={"massive"}>
                <Loader size="large">{t("loading.defect")}</Loader>
              </Dimmer>
            </Segment> }</>
        ))}

      </Layout.Article>
  </>
  )
}

export default RecordingDetails