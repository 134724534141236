import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Grid, Icon, Input, Table } from "semantic-ui-react";
import Modal from "../../../components/Modal/Modal";
import { IUsersAdapter } from "../../../models/user.models";
import { updateUsersService } from "../controllers/area_service_details.controller";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";

interface IProps {
    area: string,
    service: string,
    backoffice: string,
    open: boolean,
    setOpen: (open: boolean) => void,
    setReloadUsers: (reload: boolean) => void,
    users: IUsersAdapter[] | undefined,
    usersArea: IUsersAdapter[] | undefined,
}

const AreaAdministrationServiceDetailsAddEmployeesModal: React.FC<IProps> = ({ area, service, backoffice, open, setOpen, setReloadUsers, users, usersArea }) => {
    
    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const { t } = useTranslation();
    
    const [usersToAdd, setUsersToAdd] = useState<IUsersAdapter[]>([]);
    const [usersFilter, setUsersFilter] = useState<IUsersAdapter[]>([]);
    const [usersFilterArea, setUsersFilterArea] = useState<IUsersAdapter[]>([]);
    
    useEffect(() => {
        if (open && usersArea) {
            setUsersFilterArea(usersArea);
        }
    }, [open, usersArea]);
    useEffect(() => {
        if (open && usersArea) {
            setUsersFilterArea(usersArea);
        }
    }, []);

    // Handler para filtrar usuarios por área
    const handleFilterUsersArea = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        if (usersArea) {
            const filteredUsers = usersArea.filter(user => user.Name.toLowerCase().includes(searchTerm));
            setUsersFilterArea(filteredUsers);
        }
    };

    // Handler para filtrar usuarios por servicio
    const handleFilterUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        if (users) {
            const filteredUsers = users.filter(user => user.Name.toLowerCase().includes(searchTerm));
            setUsersFilter(filteredUsers);
        }
    };

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={t('detail.modal.title')}
            content={
                <>
                    <Grid divided="vertically">
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <p><b>{t('detail.modal.users_area')}</b></p>
                                <Input fluid placeholder="Buscar Funcionario" type="text" onChange={handleFilterUsersArea} />
                                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                                    <Table basic="very">
                                        <Table.Body>
                                            {usersFilterArea !== undefined && usersFilterArea.map(user =>
                                                <Table.Row key={user.Code}>
                                                    <Table.Cell>
                                                        <input
                                                            type="checkbox"
                                                            style={{ marginRight: "8px" }} // Agregar margen a la izquierda del checkbox
                                                            onChange={() => {
                                                                const userExists = usersToAdd.find(u => u.Code === user.Code);
                                                                if (!userExists) {
                                                                    setUsersToAdd(prevUsers => [...prevUsers, user]);
                                                                } else {
                                                                    setUsersToAdd(prevUsers => prevUsers.filter(u => u.Code !== user.Code));
                                                                }
                                                            }}
                                                            checked={usersToAdd.some(u => u.Code === user.Code)}
                                                        />
                                                        {user.Name}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>{t('detail.modal.users_service')}</b></p>
                                <Input fluid placeholder="Buscar Funcionario" type="text" onChange={handleFilterUsers} />
                                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                                    <Table basic="very">
                                        <Table.Body>
                                            {usersFilter.length > 0 ?
                                                usersFilter.map(user =>
                                                    <Table.Row key={user.Code}>
                                                        <Table.Cell>{user.Name}</Table.Cell>   
                                                    </Table.Row>
                                                )
                                                :
                                                users && users.map(user =>
                                                    <Table.Row key={user.Code}>
                                                        <Table.Cell>{user.Name}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <hr />
                    <p><b>{t('detail.modal.users_add')}</b></p>
                    {usersToAdd.length > 0 ?
                        usersToAdd.map(user =>
                            <Button
                                key={user.Code}
                                icon
                                labelPosition='right'
                                color="green"
                                onClick={() => setUsersToAdd(prevUsers => prevUsers.filter(u => u.Code !== user.Code))}
                            >
                                {user.Name}
                                <Icon name='times' />
                            </Button>
                        )
                        :
                        <span>{t('detail.notselected_users')}</span>
                    }
                </>
            }
            actions={
                <>
                    <Button color="grey" onClick={() => setOpen(false)}>{t('detail.btn_cancel')}</Button>
                    <Button color="red" onClick={() => {
                        updateUsersService(service, usersToAdd, backoffice, dispatch, callEndpoint);
                        setUsersToAdd([]);
                        setReloadUsers(true);
                        setOpen(false);
                    }}>{t('detail.btn_add_users')}</Button>
                </>
            }
        />
    );
};

export default AreaAdministrationServiceDetailsAddEmployeesModal;
