import React, { useContext, useEffect, useState } from "react";

import * as XLSX from "xlsx";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Link } from "react-router-dom";

import { registerUsers } from "../../../assets/users";

import { LayoutContext } from "../../../context/LayoutContext";
import { AuthContext } from "../../../context/AuthContext";

import Layout from "../../Layout/LayoutContainer";
import { Table, Icon, Form, Loader, Checkbox } from "semantic-ui-react";
import { Button } from "../../Button/Button";
import { toast } from "react-toastify";

interface UserList {
  code?: string;
  name?: string;
  mail?: string;
  active?: boolean;
}

const AddUsers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const translation = "organitzation.user.excel";
  const defaultUserList: UserList[] = [];
  const [users, setUsers] = useState(defaultUserList);
  const [pushUsers, setPushUsers] = useState(false);

  const layoutContext = useContext(LayoutContext);
  const authContext = useContext(AuthContext);
  const { setTitle, setBreadcrumb } = layoutContext;

  const historyHandleClick = () => {
    history.push("/organitzation/");
  };

  const arrayButtons = [
    {
      icon: "file excel",
      message: t(`${translation}.btn.template_file`),
      onClick: () => document.getElementById("downloadFileTemplate")?.click(),
    },
    {
      icon: "users plus",
      message: t(`${translation}.btn.csv`),
      onClick: () => document.getElementById("uploadFileToCloud")?.click(),
    },
    {
      icon: "arrow left",
      message: "go_back",
      onClick: historyHandleClick,
    },
  ];

  useEffect(() => {
    setTitle(t(`${translation}.title`));
    setBreadcrumb([
      {
        name: t("organitzation.panel_org.title"),
        link: "/organitzation",
      },
      {
        name: t("organitzation.user.panel.title"),
        link: `/organitzation/${authContext.account.profile.organization}/user`,
      },
      {
        name: t(`${translation}.title`),
      },
    ]);
  }, []);

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const result = event.target.result;

      const csv = XLSX.read(result, { type: "binary" });
      const sheet = csv.SheetNames[0];
      const ws = csv.Sheets[sheet];

      const data = XLSX.utils.sheet_to_csv(ws, { FS: "\t" });

      processData(data);
    };

    reader.readAsBinaryString(file);
  };

  const processData = (data: string) => {
    const rows = data.split(/\r\n|\n/);
    const list: UserList[] = [];

    for (let index = 1; index < rows.length - 1; index++) {
      const row = rows[index].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      const columns = row[0].split("\t");

      const user: UserList = {
        code: columns[0],
        name: columns[1],
        mail: columns[2],
        active: true,
      };
      list.push(user);
    }

    setUsers(list);
    setPushUsers(true);
  };

  const handleRegisterUsers = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    registerUsers(JSON.stringify(users), backoffice)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t(`${translation}.message.register.error`));
        }
      })
      .then((response) => {
        toast(t(`${translation}.message.register.success`), { type: "success"});
        setUsers([]);
        setPushUsers(false);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <>
      <Layout.Main>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t(`${translation}.table.code`)}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t(`${translation}.table.name`)}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t(`${translation}.table.email`)}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.map((user) => {
              return (
                <Table.Row>
                  <Table.Cell>{user.code}</Table.Cell>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>{user.mail}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {pushUsers && (
          <button className="btn btn-danger" onClick={handleRegisterUsers}>
            {t(`${translation}.btn.register`)}
          </button>
        )}
      </Layout.Main>
      <Layout.Article>
        {arrayButtons.map((button) => (
          <Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
        <input
          id="uploadFileToCloud"
          className="d-none"
          type="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleFileUpload}
        />
        <Link
          id="downloadFileTemplate"
          className="d-none"
          to="/UsersTemplate.csv"
          target="_blank"
          download
        >
          {t(`${translation}.btn.template_file`)}
        </Link>
      </Layout.Article>
    </>
  );
};

export default AddUsers;
