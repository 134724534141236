import React, { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FullCalendar, { CalendarApi } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CALocale from "@fullcalendar/core/locales/ca";
import { selectSlotsCalendar } from "../../assets/slots";
import { useTranslation } from "react-i18next";
import { Dimmer, Loader } from "semantic-ui-react";
import moment from "moment";
import 'moment/locale/ca';
import i18next from "i18next";

export type SlotCalendar = {
  id?: string;
  title?: string;
  start: string;
  end: string;
  display?: string;
  eventColor?: string;
  backgroundColor?: string;
  textColor?: string;
  color?: string;
};
interface BOCalendarProps {
  loading: boolean;
  calendarRef: React.RefObject<FullCalendar>;
  handleEventsApi: Function;
  forceChacheRef?: any;
}

const BOCalendar: FC<BOCalendarProps> = ({
  loading,
  calendarRef,
  handleEventsApi,
  forceChacheRef,
}: BOCalendarProps): ReactElement => {
  const { t } = useTranslation();
  const slotMonth = useSelector(selectSlotsCalendar);
  const [eventsSlot, setEventsSlot] = useState<SlotCalendar[]>([]);
  const [cache, setCache] = useState<any>({});

  const language = i18next.language;
  console.log("LANGUAGE", language)
  moment.locale(language);
  console.log("MOMENT", moment.locale(language));

  const restartCache = () => {
    setCache({});
  };

  useEffect(() => {
    const date = moment(calendarRef.current?.getApi().getDate()).format(
      "YYYY-MM"
    );
    setCache({ ...cache, [date]: eventsSlot });
  }, [eventsSlot]);

  useEffect(() => {
    const c = calendarRef.current?.getApi();
    if (c !== undefined) {
      handleEventsApi(c);
    }
    forceChacheRef(restartCache);
  }, []);

  const getEvents = async (c: CalendarApi) => {
    const date = moment(c.getDate()).format("YYYY-MM");
    const cacheSlot = cache[date];
    if (cacheSlot) {
      setEventsSlot(cacheSlot);
    } else {
      await handleEventsApi(c);
    }
  };

  useEffect(() => {
    setEventsSlot(slotMonth);
  }, [slotMonth]);

  return (
    <div id={"container-fullcalendar"} className={"t"}>
      <div className={`tt ${loading ? "sise" : "none"}`}>
        <Dimmer active inverted>
          <Loader size={"massive"}>Loading</Loader>
        </Dimmer>
      </div>
      <FullCalendar
        viewClassNames={`${loading ? "blur-loader" : ""}`}
        ref={calendarRef}
        customButtons={{
          customNext: {
            text: ">>",
            click: async () => {
              const c = calendarRef.current?.getApi();
              if (c !== undefined) {
                c.next();
                await getEvents(c);
              }
            },
          },
          customToday: {
            text: t("service_availability.today"),
            click: async () => {
              const c = calendarRef.current?.getApi();
              if (c !== undefined) {
                c.today();
                await getEvents(c);
              }
            },
          },
          customPrev: {
            text: "<<",
            click: async () => {
              const c = calendarRef.current?.getApi();
              if (c !== undefined) {
                c.prev();
                await getEvents(c);
              }
            },
          },
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "customPrev customToday customNext",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView={"dayGridMonth"}
        dayMaxEvents={true}
        locale={CALocale}
        events={eventsSlot}
      />
    </div>
  );
};
export default BOCalendar;
