import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Checkbox, Dimmer, Form, Loader } from "semantic-ui-react";

interface Props {
  title: string;
  value: string;
  onEditorChange: (s: string) => void;
  id: string;
}

export default (props: Props) => {
  return (
    <Form.Field>
      <label style={{ marginBottom: "20px", marginTop: "20px" }}>
        {" "}
        {props.title}
      </label>
      <Editor
        apiKey = {process.env.REACT_APP_TINY_MCE_API_KEY}
        init={{
          height: 200,
          menubar: false,
          plugins: "link image media",
          language: "ca",
          toolbar:
            "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | code |image | media ",
          file_picker_types: "image",
          /* and here's our custom image picker*/
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.onchange = function () {
              var file = input.files![0];

              var reader = new FileReader();
              reader.onload = function () {
                /* call the callback and populate the Title field with the file name */
                cb({ title: file.name });
              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
        }}
        initialValue={props.value}
        onEditorChange={(text) => props.onEditorChange(text)}
        id={props.id}
        textareaName={props.id}
      />
    </Form.Field>
  );
};
