import React from "react";
import i18next from "i18next";

type Option = {
  accessor: string;
  value: string;
};
interface Props {
  header: string;
  inputType?: string;
  name: string;
  onChange: (x: string, y: string) => void;
  options?: Option[];
  classNameInput?: string;
  classNameContainer?: string;
  value?: string;
}

export default (props: Props) => {
  return (
    <div className={`d-col px-0 mt-2 ${props.classNameContainer || ""}`}>
      <label>{props.header}</label>
      {props.options ? (
        <select
          id="cars"
          name="cars"
          className="w-100 "
          onChange={(event) => props.onChange(event.target.value, props.name)}
        >
          <option value={""}>{""}</option>
          {props.options.map(({ accessor, value }) => (
            <option value={accessor}>{value}</option>
          ))}
        </select>
      ) : (
        <input
          className={`form-control ${props.classNameInput || ""}`}
          name={props.name}
          type={props.inputType || "text"}
          value={props.value}
          onChange={(event) => props.onChange(event.target.value, props.name)}
        />
      )}
    </div>
  );
};
