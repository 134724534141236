import moment from "moment";
import React from "react";
import { Button, Form, Icon } from "semantic-ui-react";

interface IAreaAdministrationServiceDetailsMeetingFormDate{
    date: any,
    setDate: any
}

const AreaAdministrationServiceDetailsMeetingFormDate = (props: IAreaAdministrationServiceDetailsMeetingFormDate) => {
    return (
        <div id="area__details__form__meeting">
            <Button icon color="red" onClick={() => props.setDate( moment(props.date).add(-1, 'days').format("YYYY-MM-DD") )}>
                <Icon name="caret left" />
            </Button>
            <Form.Input 
                type={"date"}
                value={moment(props.date).format("YYYY-MM-DD")}
                onChange={(e) => props.setDate(moment(e.target.value).format("YYYY-MM-DD"))}
            />
            <Button icon color="red" onClick={() => props.setDate( moment(props.date).add(1, 'days').format("YYYY-MM-DD") )}>
                <Icon name="caret right" />
            </Button>
        </div>
    )
}

export default AreaAdministrationServiceDetailsMeetingFormDate;