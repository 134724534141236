import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../context/LayoutContext";
import { AuthContext } from "../../context/AuthContext";
import { Button } from "../../components/Button/Button";
import Layout from "../../components/Layout/LayoutContainer";

type areaLang = {
  displayName: string;
  code: string;
};

const DashboardArea: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const [token, setToken] = useState("");
  const { setTitle, setBreadcrumb } = layoutContext;

  useEffect(() => {
    setTitle(t(`breadcrump.management`));
    setBreadcrumb([
      {
        name: t(`breadcrump.management`),
      },
    ]);
  }, []);

  const dispatch = useDispatch();

  const handleAreaRecordingClick = () => {
    history.push(`/management/recording`);
  };

  const arrayButtonsArea = [
    {
      icon: "video",
      message: t("management.btn_recordings"),
      onClick: handleAreaRecordingClick,
    },
  ];

  return (
    <>
      <Layout.Main>{/* <h2>{t("area.service_select")}</h2> */}</Layout.Main>
      <Layout.Article>
        {arrayButtonsArea.map((button) => (
          <Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </Layout.Article>
    </>
  );
};

export default DashboardArea;
