import React, {
  FC,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Message,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import {
  getReservedMeetings,
  cleanDeleteSlots,
  selectDeleteSlots,
  SlotTableDelete,
} from "../../../assets/slots";
import {
  fetchGetSlotHasEvent,
  selectEventWithMeetings,
} from "../../../assets/events";
import { AuthContext } from "../../../context/AuthContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import StateContainer from "../../StateContainer";
import { Slot } from "../../../models/Slot";
import { setResponsiveMode } from "office-ui-fabric-react/lib/utilities/decorators/withResponsiveMode";
import { date } from "yup/lib/locale";
import { RiContactsBookLine } from "react-icons/ri";

interface BOTableDisponibilitatDeleteProps {
  serviceCode: string;
  slotArray: SlotTableDelete[];
  handleOpen: Function;
  handleDelete: Function;
  handleToday: Function;
  handleQuestion: Function;
  refDeleteSelectedDays?: Function;
  refQuestionDelete?: Function;
  refHandleDefaultValues?: Function;
  loading: boolean;
}
type CheckInfo = {
  time: string;
  day: string;
  data: boolean;
};
type MeetInfo = {
  data: string;
  reserved: boolean
};

const defaultMeet: MeetInfo = {
  data: "",
  reserved: false,
};
const defaultCheck: CheckInfo = {
  time: moment().format(),
  day: moment().format("YYYYMMDD"),
  data: false,
};

const defaultSlotTableDelete: SlotTableDelete = {
  day: "",
  start: "",
  duration: 0,
  count: 0,
};

const BOTableDisponibilitatDelete: FC<BOTableDisponibilitatDeleteProps> = ({
  serviceCode,
  slotArray,
  handleOpen,
  loading,
  handleDelete,
  handleToday,
  handleQuestion,
  refDeleteSelectedDays,
  refQuestionDelete,
  refHandleDefaultValues,
}: BOTableDisponibilitatDeleteProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const responseDeleteSlots = useSelector(selectDeleteSlots);
  const eventWithMeetings = useSelector(selectEventWithMeetings);
  const [slotSelected, setSlotSelected] = useState<SlotTableDelete>(
    defaultSlotTableDelete
  );
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [checkMeeting, setCheckMeeting] = useState<CheckInfo>(defaultCheck);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openConfirmEvent, setOpenConfirmEvent] = useState<boolean>(false);
  const [openDeleteReserved, setOpenDeleteReserved] = useState<boolean>(false);
  const [openQuestion, setOpenQuestion] = useState<boolean>(false);

  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [selReservDays, setSelReservDays] = useState<unknown>([]);
  const [reservDays, setReservDays] = useState<any[]>([]);
  const [electedReserv, setElectedReserv] = useState<string[]>([]); //dias selecionados + reservados


  useEffect(() => {
    (async () => {
      console.log("slotArray", slotArray);
      if (responseDeleteSlots !== null) {
        if (responseDeleteSlots.response === true) {      
          toast((
            `${t("message.availability.delete.success_01")}`
          ), { type: "success"});
          await setOpenConfirm(false);
          await handleOpen(false);
          handleCancelConfirmEvent();
          await handleToday();
        } else {
          toast(t("message.availability.delete.error"), { type: "error"});
          await handleOpen(false);
        }
        const y = moment().format("YYYY");
        const m = moment().format("M");
        setMonth(m);
        setYear(y);
      }
    })();
    
  }, [responseDeleteSlots]);

  useEffect(() => {
    (async () => {
      if (slotSelected.day !== "") {
        await dispatch(
          fetchGetSlotHasEvent(serviceCode, slotSelected.day, token)
        );
        setCheckMeeting({
          time: moment().format(),
          day: slotSelected.day,
          data: true,
        });
      }
    })();
  }, [slotSelected]);

  useEffect(() => {
    (async () => {
      if (checkMeeting.data) {
        if (eventWithMeetings.length === 0) {
          setOpenConfirm(true);
        } else {
          setOpenConfirmEvent(true);
        }
      }
    })();
  }, [checkMeeting]);

  useEffect(() => {
    if (currentMonth) {
      const m = currentMonth.split("");
      const mon = m[0] + m[1]; //moment(currentMonth).format("MM")
      const y = m[2] + m[3] + m[4] + m[5]; // moment(m).format("YYYY");
      const reservedMeetings = async () => await getReservedMeetings(serviceCode, y, mon, token);
      let promise = new Promise ((resolve, reject) => {
        try {
          resolve(reservedMeetings());
        } catch (error) {
          reject(error);
        }
      })
      promise.then(function (reservedMeetings) {
        setSelReservDays(reservedMeetings);
        });
    }
  });

  useEffect(() => {
    handleSelectedAndReservedDays();
    console.log("ELECTEDRESERV", electedReserv);
    console.log("SELECTED", selectedDays);
    if(electedReserv.length > 0) {
      refQuestionDelete && refQuestionDelete(handleDeleteQuestion);
    }
    else {
      refDeleteSelectedDays && refDeleteSelectedDays(handleDeleteSelected);
    }
  }, [selectedDays]);

  useEffect(() => {
    return () => {
      dispatch(cleanDeleteSlots());
    };
  }, []);


  const handleReservDays = () => {
        const array  = selReservDays.filter((item, index, self) => index === self.findIndex((t) => (t.date === item.date)));
        setReservDays([]);
        array.map((slot) => {
          reservDays.push(slot.date);
        }) 
  }

  const handleSelectedAndReservedDays = () => {
    handleReservDays();
    reservDays.map ((day) => {
      for (let i = 0; i < selectedDays.length; i++) {    
        if (day === selectedDays[i]) {
          let b : boolean = false;
          for (let i = 0; i < electedReserv.length; i++) {
            if (day === electedReserv[i]) b = true;
          }
          if (!b) electedReserv.push(day);
        }
      }
    })
  }

  const handleDeleteQuestion = () => {
      setOpenQuestion(true);
      console.log("Open", openQuestion);

      //handleQuestion();
  }

  const handleConfirmDelete = (slot: SlotTableDelete) => {
    setSlotSelected(slot);
    setDay(moment(slot.day).format("DD"));
    setMonth(moment(slot.day).format("MM"));
    setYear(moment(slot.day).format("YYYY"));
  };

  const handleCancelConfirm = () => {
    setOpenConfirm(false);
    setSlotSelected(defaultSlotTableDelete);
  };
  
  const handleCancelConfirmEvent = () => {
    setOpenConfirmEvent(false);
    setSlotSelected(defaultSlotTableDelete);
    setOpenQuestion(false);
  };


  const handleDeleteSelected = async () => {
    await Promise.all(
      selectedDays.map(async (day) => {
        await handleDelete(day, false);
      })
    );
    console.log("2", openQuestion);
    setOpenConfirm(false);
    handleToday();
  };

  const handleConfirmEvent = (force: boolean) => {
    console.log("entra ara");
    console.log("ELECTED RESERV", electedReserv);
    console.log("Selected Days E", selectedDays);
    electedReserv.map( async (day) => {
      await handleDelete(day, force);
      setElectedReserv(electedReserv.filter((data) =>  (data !== day)));
      setSelectedDays(selectedDays.filter((dia) => (dia !== day)));
    })
    selectedDays.map(async (day) => {
      await handleDelete(day, false);
    })
    
  };
  const handleSelectedDisponibility = (day: string) => {
    if (selectedDays.find((_) => day === _)) {
      setElectedReserv(electedReserv.filter((data) => day !== data));
      setSelectedDays(selectedDays.filter((_) => day !== _));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const currentMonth = slotArray[0]
    ? moment(slotArray[0].day).format("MMYYYY")
    : undefined;

  const handleSelectedAllDisponibility = (isSelectAll: boolean) => {
    const cleanSelectedDays = selectedDays.filter(
      (_) => moment(_).format("MMYYYY") != currentMonth
    );
    if (isSelectAll) {
      setSelectedDays(cleanSelectedDays);
    } else {
      setSelectedDays([...selectedDays, ...slotArray.map((_) => _.day)]);
    }
  };

  const allMonthSelected =
    selectedDays.filter((_) => moment(_).format("MMYYYY") == currentMonth)
      .length == slotArray.length;
  return (
    <>
      <StateContainer
        className="w-100"
        loading={loading}
        data={slotArray}
        emptyStateMessage={t("message.availability.no_slots")}
      >
        <Fragment>
          <div className="w-100 d-flex justify-content-end">
            <Checkbox
              className="mt-2 mr-2"
              label={t("message.availability.select_all")}
              checked={allMonthSelected}
              onChange={() => {
                handleSelectedAllDisponibility(allMonthSelected);
              }}
            />
          </div>
          <Table className="" key={"table-user"}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("message.availability.day")}{" "}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("message.availability.event")}{" "}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("message.availability.start")}{" "}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("message.availability.duration")}{" "}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("message.availability.delete_word")}{" "}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {slotArray.map((slot: SlotTableDelete, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{moment(slot.day).format("DD")}</Table.Cell>
                    <Table.Cell>
                      {slot.count} {t("message.availability.event")}{" "}
                    </Table.Cell>
                    <Table.Cell>{slot.start}</Table.Cell>
                    <Table.Cell>
                      {slot.duration} {t("message.availability.minutes")}{" "}
                    </Table.Cell>
                    <Table.Cell>
                      {/*<Button
                    inverted
                    color="red"
                    icon="delete"
                    onClick={() => handleConfirmDelete(slot)}
                /> */}
                      <Checkbox
                        checked={
                          selectedDays.find((_) => slot.day === _) !== undefined
                        }
                        onChange={() => {
                          handleSelectedDisponibility(slot.day);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                  
                );
              })}
            </Table.Body>
          </Table>

          <Modal
            id={"modal-delete-reserved"}
            size={"tiny"}
            onClose={handleCancelConfirmEvent}
            open={openQuestion}
            >
            <Modal.Header>
              {t("message.availability.delete.title_cite")}
              </Modal.Header>
              <Modal.Content className={"br0"}>
                {electedReserv.map((day : string) => {
                  return<><p>{`${t("message.availability.delete.message_cite_01")} 
                  ${moment(day).format("DD")}
                  ${t("message.availability.delete.message_cite_02")}
                  ${moment(day).format("MM")}
                  ${t("message.availability.delete.message_cite_03")}
                  ${moment(day).format("YYYY")}
                  ${t("message.availability.delete.message_cite_04")}
                  `}</p></>})}
                <Message size="mini">
                  <p>{t("message.availability.delete.message_delete_01")}</p>
                  <p>{t("message.availability.delete.message_delete_02")}</p>
                </Message>
              </Modal.Content>

                <Modal.Actions>
                  <Button onClick={handleCancelConfirmEvent} negative>
                    {t("btn.cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      handleConfirmEvent(false);
                    }}
                    positive
                    >
                    {t("btn.delete_availability")}
                  </Button>
                  <Button
                    onClick={() => {
                      handleConfirmEvent(true);
                    }}
                    positive
                    >
                    {t("btn.delete_all")}
                  </Button>
                  </Modal.Actions>
              </Modal>
{/*
          
           <Modal
                id={"modal-delete-all"}
                size={"tiny"}
                onClose={handleCancelConfirmEvent}
                open={openConfirmEvent}
                >
                <Modal.Header>
                {t("message.availability.delete.title_cite")}
                </Modal.Header>
                <Modal.Content className={"br0"}>
                  <p>{`${t("message.availability.delete.message_cite_01")} ${moment(
                    slotSelected.day
                    ).format("DD")} ${t(
                      "message.availability.delete.message_cite_02"
                      )} ${t(`calendar.${moment(slotSelected.day).format("MM")}`)} ${t(
                        "message.availability.delete.message_cite_03"
                        )} ${moment(slotSelected.day).format("YYYY")} ${t(
                          "message.availability.delete.message_cite_04"
                          )}`}</p>
                          <Message size="mini">
                            <p>{t("message.availability.delete.message_delete_01")}</p>
                            <p>{t("message.availability.delete.message_delete_02")}</p>
                          </Message>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={handleCancelConfirmEvent} negative>
                      {t("btn.cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        handleConfirmEvent(false);
                      }}
                      positive
                      >
                      {t("btn.delete_availability")}
                    </Button>
                    <Button
                      onClick={() => {
                        handleConfirmEvent(true);
                      }}
                      positive
                      >
                      {t("btn.delete_all")}
                    </Button>
                    </Modal.Actions>
                </Modal>
            
            <Modal
            id={"modal-delete"}
            size={"mini"}
            onClose={handleCancelConfirm}
            open={openConfirm}
      >
      <Modal.Header>
      {t("message.availability.delete.title_no_cite")}
      </Modal.Header>
      <Modal.Content className={"br0"}>
      <p>{t("message.availability.delete.question")}</p>
      <Message color={"red"}>
      <p className={"text-center"}>
      {moment(slotSelected.day).format("DD MM YYYY")}
      </p>
      </Message>
      </Modal.Content>
      <Modal.Actions>
      <Button onClick={handleCancelConfirm} negative>
      {t("message.availability.cancel")}
      </Button>
      <Button
      onClick={() => {
        handleConfirm();
      }}
      positive
      >
      {t("btn.delete")}
      </Button>
      </Modal.Actions>
    </Modal> {*/}
        </Fragment>
      </StateContainer>
    </>
  );
};

export default BOTableDisponibilitatDelete;
