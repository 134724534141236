import React, { useContext, useEffect } from "react";
import Screen from "./Screen";
import { useTranslation } from "react-i18next";
import { useError } from "../../../hooks/useError";
import { AuthContext } from "../../../context/AuthContext";
import { LayoutContext } from "../../../context/LayoutContext";
import { useForm } from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { addOrganization } from "../../../assets/api";
import { useHistory } from "react-router-dom";
interface Props {}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const userCode = authContext.account.profile.code;
  const { setTitle, setBreadcrumb } = layoutContext;
  const { error, setError } = useError();
  const { t } = useTranslation();
  const history = useHistory();
  const defaultForm: any = {
    code: "",
    displayNameCA: "",
    displayNameES: "",
    displayNameEN: "",
    displayNameOC: "",
    email: "",
    phone: "",
    url: "",
    active: false,
  };
  const [form, setForm, handleInputChange, reset] = useForm(defaultForm);

  useEffect(() => {
    setTitle(t("administration.add_organization.title"));
    setBreadcrumb([
      {
        name: t("administration.title"),
        link: "/administration",
      },
      {
        name: t("administration.add_organization.title"),
      },
    ]);
  }, []);
  const handleSubmit = async () => {
    const getCulture = (id: number) => {
      switch (id) {
        case 0:
          return "ca";
        case 1:
          return "es";
        case 2:
          return "oc";
        case 3:
          return "en";
        default:
          return "ca";
      }
    };
    const {
      email,
      phone,
      url,
      active,
      displayNameCA,
      displayNameES,
      displayNameEN,
      displayNameOC,
      code,
    } = form;
    const languages = [displayNameCA, displayNameES, displayNameEN, displayNameOC];
    const formatedLanguages = languages.map((displayName, id) => ({
      culture: getCulture(id),
      displayName,
    }));
    const bodyCreateOrganization = {
      email,
      phone,
      url,
      active,
      displayName: displayNameCA,
      languages: formatedLanguages,
      code,
      recordMeetingEnabled: true,
    };
    const token = authContext.account.access_token;
    try {
      const response = await addOrganization(bodyCreateOrganization, token);
      if (!response.ok) {
        throw Error;
      }
      toast(t("administration.add_organization.success"), { type: "success"});
      history.push(`/administration`);
    } catch (error) {
      toast(t("administration.add_organization.error"), { type: "error"});
    }
  };

  const getFormat = (key: string) => {
    if (key == "phone") return "phone";
    else if (key == "email") return "email";
    else if (key == "active") return "boolean";
    else return "text";
  };

  const getRequired = (key: string) => {
    if (key == "code") return true;
    else if (key == "displayNameCA") return true;
    else if (key == "displayNameES") return true;
    else if (key == "displayNameEN") return true;
    else if (key == "displayNameOC") return true;
    else return false;
  };
  const getLabelInfo = (key: string) => {
    return {
      format: getFormat(key),
      required: getRequired(key),
      label: key == "phone" ? "+34" : undefined,
    };
  };

  const formatedCreated = (form: any) => {
    return Object.entries(form).map(([key, value]: any) => {
      const labelInfo: any = getLabelInfo(key);
      return {
        ...labelInfo,
        key,
        value,
      };
    });
  };
  return (
    <Screen
      handleSubmit={handleSubmit}
      form={formatedCreated(form)}
      handleInputChange={handleInputChange}
    />
  );
};
