import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";

import { getAdminOrganization, addUser } from "../../../assets/api";

import { AuthContext } from "../../../context/AuthContext";
import { Languages } from "../../../types/service";
import { UserToAdd } from "../../../types/organization";

import Layout from "../../Layout/LayoutContainer";
import Card from "../../Card/Card";
import {
  Button as ButtonSemantic,
  Dimmer,
  Form,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import { Button } from "../../Button/Button";
import { LayoutContext } from "../../../context/LayoutContext";
import { toast } from "react-toastify";

const initializeUser: UserToAdd = {
  active: false,
  code: "",
  name: "",
  mail: "",
};

const AddUser = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;
  const [loading, setLoading] = useState(true);
  const [checkbox, setCheckbox] = useState(false);
  const [form, setForm, handleInputChange, reset] = useForm(initializeUser);

  useEffect(() => {
    setTitle(t("organitzation.user.create.title"));
    setBreadcrumb([
      {
        name: t("organitzation.panel_org.title"),
        link: "/organitzation",
      },
      {
        name: t("organitzation.user.panel.title"),
        link:
          "/organitzation/" +
          authContext.account.profile.organization +
          "/user",
      },
      {
        name: t("organitzation.user.create.title"),
      },
    ]);

    setLoading(false);
  }, []);

  const historyHandleClick = () => {
    history.push(
      "/organitzation/" + authContext.account.profile.organization + "/user"
    );
  };

  const handleSubmit = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    setForm((form: UserToAdd) => {
      form.active = checkbox;
      return form;
    });
    if (!form.code || !form.name || !form.mail) {
      toast(t(`organitzation.user.create.message.add.error`), { type: "error"});
    } else
      try {
        await addUser(form, backoffice)
          .then((response) => {
            if (response.ok) return response.json();

            throw new Error(t(`organitzation.user.create.message.add.error`));
          })
          .then((response) => {
            toast((
              t(`organitzation.user.create.message.add.success`) +
                ` (${response.code})`
            ), { type: "success"});
            historyHandleClick();
          });
      } catch (er) {
        toast((er.message), { type: "error"});
        historyHandleClick();
      }
  };

  return (
    <>
      <Layout.Main>
        {loading ? (
          <Dimmer active inverted size={"massive"}>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : (
          <>
            <Card>
              <Card.Body>
                <Form style={{ boxShadow: "none" }} className="border-0">
                  <Form.Field>
                    <label>{t("organitzation.user.create.form.code")}</label>
                    <input
                      name="code"
                      value={form.code}
                      onChange={(e) => handleInputChange(e)}
                      required
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t("organitzation.user.create.form.name")}</label>
                    <input
                      name="name"
                      value={form.name}
                      onChange={(e) => handleInputChange(e)}
                      required
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{t("organitzation.user.create.form.email")}</label>
                    <input
                      name="mail"
                      value={form.email}
                      onChange={(e) => handleInputChange(e)}
                      required
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name="active"
                      value={form.active}
                      label={t("organitzation.user.create.form.active")}
                      onChange={() => setCheckbox(!checkbox)}
                    />
                  </Form.Field>
                  <ButtonSemantic
                    negative
                    content={t("btn.cancel")}
                    className="mt-3"
                    onClick={historyHandleClick}
                  />
                  <ButtonSemantic
                    positive
                    icon="edit"
                    content={t("organitzation.user.create.title")}
                    className="mt-3"
                    onClick={handleSubmit}
                  />
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
      </Layout.Main>
    </>
  );
};

export default AddUser;
