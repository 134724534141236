import React, { useContext, useEffect, useState } from "react";
import Screen from "./Screen";
import { useTranslation } from "react-i18next";
import { useError } from "../../../hooks/useError";
import { AuthorizationModel } from "../../../models/Authorization";
import { AuthContext } from "../../../context/AuthContext";
import LayoutContainer from "../../../components/Layout/LayoutContainer";
import { LayoutContext } from "../../../context/LayoutContext";
import ButtonListAdministration from "./components/ButtonListAdministration";
import { getOrganizations } from "../../../assets/api";
import { useHistory } from "react-router-dom";
interface Props {}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const userCode = authContext.account.profile.code;
  const { setTitle, setBreadcrumb } = layoutContext;
  const { error, setError } = useError();
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const history = useHistory();

  const token = authContext.account.access_token;

  useEffect(() => {
    setTitle(t("administration.title"));
    setBreadcrumb([
      {
        name: t("administration.title"),
      },
    ]);
  }, []);
  useEffect(() => {
    (async () => {
      const organizations = await getOrganizations(token).then((response) =>
        response.json()
      );
      setOrganizations(organizations);
    })();
  }, []);

  return <Screen organizations={organizations} />;
};
