import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../Layout/LayoutContainer";
import { Button } from "../Button/Button";
import { Dimmer, Icon, Loader } from "semantic-ui-react";
import { AuthContext } from "../../context/AuthContext";
import { getAdminOrganization, getAreasByOrganization } from "../../assets/api";
import { LayoutContext } from "../../context/LayoutContext";
import { toast } from "react-toastify";
import { OrganitzationInfo } from "../../models/OrganitzationInfo";
import { Area } from "../../models/Area";
import { Fragment } from "react";
import { AreaName } from "../../pages/Name/AreaName";

type MyOrganitzation = {
  active: boolean;
  code: string;
  displayName: string;
};

const AdminOrganitzation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;
  const [loading, setLoading] = useState(true);
  const [areaList, setAreaList] = useState<Area[]>([]);

  const handleClick = () => {
    history.push(`/area/create`);
  };

  useEffect(() => {
    setTitle(t("organitzation.panel_org.title"));
    setBreadcrumb([
      {
        name: t("organitzation.panel_org.title"),
      },
    ]);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { backoffice } = await authContext.getTokenForScopes();
        let res: Response = await getAdminOrganization(backoffice);
        if (!res.ok) throw new Error(t("organitzation.message.error.code"));
        const myorg: MyOrganitzation = await res.json();

        res = await getAreasByOrganization(myorg.code, backoffice);
        if (!res.ok)
          throw new Error(
            t("organitzation.message.error.info") + ` (${myorg.displayName})`
          );
        const org: OrganitzationInfo = await res.json();
        await setAreaList(org.areas);
      } catch (error) {
        toast((error.message), { type: "error"});
      }
      setLoading(false);
    })();
  }, []);

  const arrayButtons = [
    {
      icon: "setting",
      message: "organitzation.panel_org.btn.settings",
      onClick: () => history.push("/organitzation/settings"),
    },
    {
      icon: "video",
      message: t("organitzation.panel_org.btn.recordings"),
      onClick: () =>
        history.push(
          `/organitzation/${authContext.account.profile.organization}/recording`
        ),
    },
    {
      icon: "plus",
      message: t("organitzation.panel_org.btn.area"),
      onClick: handleClick,
    },
    {
      icon: "user",
      message: t("organitzation.panel_org.btn.user"),
      onClick: () =>
        history.push(
          `/organitzation/${authContext.account.profile.organization}/user`
        ),
    },
  ];

  return (
    <>
      <Layout.Main>
        <h2>{t("organitzation.panel_org.select")}</h2>
        {loading ? (
          <Dimmer active inverted size={"massive"}>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : (
          <Fragment>
            <table className="table">
              <tbody>
                {areaList.length > 0 ? (
                  areaList.map((area) => (
                    <tr>
                      <td className="p-3">{area.code}</td>
                      <td className="p-3">
                        <AreaName codeArea={area.code} />
                      </td>
                      <td className="text-right p-3">
                        <Link to={`/area/${area.code}`}>
                          <Icon name="eye" />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>{t("organitzation.panel_org.no_found")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Fragment>
        )}
      </Layout.Main>
      <Layout.Article>
        {arrayButtons.map((button) => (
          <Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </Layout.Article>
    </>
  );
};

export default AdminOrganitzation;
