import React, { FC, ReactElement } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import AutoGrid from "./AutoGrid";
import { AutoGridLabelType, AutoGridTypeType } from "./AutoGrid/type";

interface BOFormCalendarProps {
  handleSubmit: Function;
  handleOpen: Function;
}
const handleDateMin = () => {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  return d;
};

const BOFormCalendar: FC<BOFormCalendarProps> = ({
  handleSubmit,
  handleOpen,
}: BOFormCalendarProps): ReactElement => {
  const { t } = useTranslation();
  const initialValues: any = {
    dayStart: "",
    dayEnd: "",
    firstSlotTime: "08:00",
    end: "20:00",
    roomAvail: 1,
    slotDuration: 10,
    slotBreak: 0,
  };
  const labelsFormik: AutoGridLabelType[] = Object.entries(initialValues).map(
    ([key, value]) => {
      let type: AutoGridTypeType = "number";
      if (typeof value == typeof "") {
        if (key == "dayStart" || key == "dayEnd") type = "date";
        if (key == "start" || key == "end" || key == "firstSlotTime")
          type = "time";
      }
      return {
        key,
        type,
      };
    }
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      dayStart: Yup.date()
        .required("Obligatori")
        .min(handleDateMin(), "tiene que ser de dias despues de hoy"),
      dayEnd: Yup.date()
        .required("Obligatori")
        .min(
          Yup.ref("dayStart"),
          "El camp 'Dia d'inici' ha de ser anterior al dia de fi"
        ),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Form
      id={"bo-form-calendar"}
      className="clean"
      onSubmit={formik.handleSubmit}
    >
      <Grid>
        {
          <AutoGrid
            labels={labelsFormik}
            numRows={2}
            renderCell={(label: AutoGridLabelType) => {
              return (
                <Form.Input
                  id={label.key}
                  name={label.key}
                  type={label.type}
                  label={t(
                    `service_availability.modal_add_availability.${label.key}`
                  )}
                  onChange={formik.handleChange}
                  value={formik.values[label.key]}
                  error={formik.errors[label.key]}
                />
              );
            }}
          />
        }
        <Grid.Row className={"br0"}>
          <Grid.Column className={"br0 d-flex justify-content-end"}>
            <Button
              className={""}
              negative
              content={t("btn.cancel")}
              onClick={() => handleOpen(false)}
            />
            <Button
              className={"ml-3"}
              positive
              content={t("btn.add")}
              type={"submit"}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default BOFormCalendar;
