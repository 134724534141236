import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import {
  fetchDeleteSlots,
  fetchGetCountSlotsByMonth,
  selectSlotsDeleteByMonth,
  getReservedMeetings,
} from "../../assets/slots";
import moment from "moment";
import BOTableDisponibilitatDelete from "./BOTableDisponibilitatDelete/BOTableDisponibilitatDelete";
import { useTranslation } from "react-i18next";
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import { Button } from "../Button/Button";
import { Slot } from "../../models/Slot";

declare type FormControlElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;
export interface BOModalDisponibilitatDeleteProps {
  serviceCode: string;
  openDelete: boolean;
  handleOpen: Function;
  handleLoading: Function;
  handleToday: Function;
}

let handleDeleteSelected: any;
let handleQuestionDelete: any;
let handleDefaultValues: any;
const BOModalDisponibilitatDelete: FC<BOModalDisponibilitatDeleteProps> = ({
  serviceCode,
  openDelete,
  handleOpen,
  handleToday,
}: BOModalDisponibilitatDeleteProps): ReactElement => {
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectorMonth = useSelector(selectSlotsDeleteByMonth);
  const [open, setOpen] = useState<boolean>(openDelete);
  const [month, setMonth] = useState<string>(moment().format("M"));
  const [year, setYear] = useState<string>(moment().format("YYYY"));
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoadingDelete(true);
      await dispatch(
        fetchGetCountSlotsByMonth(serviceCode, year, month, token)
      );
      setIsLoadingDelete(false);
    })();
  }, [year, month]);

  useEffect(() => {
    (async () => {
      await dispatch(
        fetchGetCountSlotsByMonth(serviceCode, year, month, token)
      );
      setOpen(openDelete);
    })();
  }, [openDelete]);

  const handleMonth = (event: ChangeEvent<FormControlElement>) => {
    setMonth(event.target.value);
  };

  const handleYear = (event: ChangeEvent<FormControlElement>) => {
    setYear(event.target.value);
  };

  const handleDelete = async (date:string , force: boolean) => {
    const y = moment(date).format("YYYY");
    const m = moment(date).format("MM");
    const d = moment(date).format("DD");
    await dispatch(fetchDeleteSlots(serviceCode, y, m, d, force, token));
    await dispatch(fetchGetCountSlotsByMonth(serviceCode, year, month, token));
  };

  const handleQuestion = async (days: string[]) => {
    console.log("HANDLE QUESTION");
  };

  return (
    <Modal
      id={"modal-disponibilitat-delete"}
      size={"tiny"}
      onClose={() => handleOpen(false)}
      open={open}
    >
      <Modal.Header>{t("message.availability.delete_slots")}</Modal.Header>
      <Modal.Content className={"br0"}>
        <Container className={"br0"}>
          <Row className={"br0"}>
            <Col md={6} className={"br0 p-0 m-0"}>
              <Row>
                <Col>
                  <InputGroup className="br0">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        {t("message.availability.month")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      min={1}
                      max={12}
                      value={month}
                      type={"number"}
                      aria-describedby="basic-addon1"
                      onChange={!isLoadingDelete ? handleMonth : undefined}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="br0">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon2">
                        {t("message.availability.year")}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      min={moment().format("YYYY")}
                      max={moment().add(5, "y").format("YYYY")}
                      value={year}
                      type={"number"}
                      aria-describedby="basic-addon2"
                      onChange={!isLoadingDelete ? handleYear : undefined}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className={"pt-2 pb-2"}>
                {t("message.availability.day_delete")}
                {` ( ${t("calendar." + moment(month, "M").format("MM"))} )`}
              </h5>
            </Col>
          </Row>
          <Row className={"row-select-delete"}>
            <Col
              className={
                "br0 col-select-delete day d-flex flex-wrap justify-content-around"
              }
            >
              <BOTableDisponibilitatDelete
                serviceCode={serviceCode}
                slotArray={selectorMonth}
                handleDelete={handleDelete}
                handleToday={handleToday}
                handleOpen={handleOpen}
                handleQuestion={handleQuestion}
                refDeleteSelectedDays={(ref: Function) => {
                  handleDeleteSelected = ref;
                }}
                refQuestionDelete={(ref: Function) => {
                  handleQuestionDelete = ref;
                }}
                refHandleDefaultValues={(ref: Function) => {
                  handleDefaultValues = ref;
                }}
                loading={isLoadingDelete}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <div className="d-flex flex-row">
          <Button onClick={async() => {
            handleOpen(false)
            }} negative size="medium">
            {t("message.availability.cancel")}
          </Button>
          <Button
            onClick={async () => {
              handleQuestionDelete && (await handleQuestionDelete());
              handleDeleteSelected && (await handleDeleteSelected());
            }}
            size="medium"
            autoLoading={true}
          >
            {t("message.availability.accept")}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
export default BOModalDisponibilitatDelete;
