import React from "react";
import Screen from "./Screen";
type Option = {
  accessor: string;
  value: string;
};
interface Props {
  header: string;
  inputType?: string;
  name: string;
  onChange: (x: string, y: string) => void;
  options?: (Option | string)[];
  classNameInput?: string;
  classNameContainer?: string;
  value?: string;
}

export default (props: Props) => {
  const options =
    props.options &&
    props.options.map((value: any) => {
      const option: Option = {
        accessor: value.accessor || value,
        value: value.value || value,
      };
      return option;
    });
  return <Screen {...props} options={options} />;
};
