import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const getStoredLanguage = () => {
  const storedLanguage = localStorage.getItem("language");
  return storedLanguage || "ca";  // idioma por defecto
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getStoredLanguage(),
    fallbackLng: "ca",
    debug: true,
    initImmediate: false,
    detection: {
      order: ["queryString", "cookie", "localStorage", "navigator"],
      cache: ["localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
