import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";

import AreaAdministrationServiceDetailsMeetingFormDate from './AreaAdministrationServiceDetailsMeetingFormDate';
import AreaAdministrationServiceDetailsMeetingsActions from './AreaAdministrationServiceDetailsMeetingsActions';
import AreaAdministrationServiceDetailsMeetingsAttendee from './AreaAdministrationServiceDetailsMeetingsAttendee';

import { EEventInTime, IEventDetailAdapter } from "../../../models/event.models";

import moment from "moment";

import { Button, Dropdown, Icon, Input, Popup, Table, TableHeaderCell } from "semantic-ui-react";
import { IUsersAdapter } from "../../../models/user.models";

interface IProps {
  users: IUsersAdapter[],
  meetings: IEventDetailAdapter[],
  setReload: any,
  date: Date,
  setDate: any,
  backoffice: string,
  shared: string
}

const AreaAdministrationServiceDetailsMeetings = ({ users, meetings, setReload, date, setDate, backoffice, shared }: IProps) => {
  const { t } = useTranslation();

  const [meetingsFilter, setMeetingsFilter] = useState<IUsersAdapter[]>();
  useEffect(() => {
    console.log("MEET1", meetings);
  }, [meetings]);

  const options = [
    { key: 'all', text: t('home.search.label.all'), value: 'all' },
    { key: 'code', text: t('home.search.label.code'), value: 'code' }
  ];

  return (
    <>
      <AreaAdministrationServiceDetailsMeetingFormDate date={date} setDate={setDate} />
      <>
        <Button icon negative labelPosition='left' onClick={() => setReload(true)}>
          <Icon name='refresh' />
          { t("refresh") }
        </Button>
        <Input
          label={<Dropdown defaultValue='Todos' options={options} />}
          labelPosition='right'
          onChange={(e) => setMeetingsFilter(meetings.filter(meeting => meeting.Code && meeting.Code.includes(e.target.value.trim())))}
        />
      </>
      <>
        <Table className="area__details__table">
          <Table.Header>
            <Table.Row>
              <TableHeaderCell>{ t("detail.meeting.title.start") }</TableHeaderCell>
              <TableHeaderCell>{ t("detail.meeting.title.code") }</TableHeaderCell>
              <TableHeaderCell>{ t("detail.meeting.title.status") }</TableHeaderCell>
              <TableHeaderCell>{ t("detail.meeting.title.action") }</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {meetingsFilter ? (
              <>
                { meetingsFilter.map(meeting => {
                  console.log("Meeting Status:", meeting.Status); // Log para depuración
                  return (
                    <Table.Row key={meeting.id} className={ 
                      meeting.InTime === EEventInTime.LaterAccess ? "later_access" : meeting.InTime === EEventInTime.EarlyAccess ? "early_access" : "in_time" 
                    }>
                      <Table.Cell>
                        { moment(meeting.Start).format("HH:mm") }
                      </Table.Cell>
                      <Table.Cell>
                        <AreaAdministrationServiceDetailsMeetingsAttendee code={meeting.Code} comment={meeting.Comment} attendees={meeting.Attendees} subjects1={meeting.Subject1} subjects2={meeting.Subject2} subjects3={meeting.Subject3}/>
                      </Table.Cell>
                      <Table.Cell>
                        { meeting.Status ? t(`detail.status.${meeting.Status.toLowerCase()}`) : t("detail.status.notattendeed") }
                      </Table.Cell>
                      <Table.Cell>
                        <AreaAdministrationServiceDetailsMeetingsActions meeting={meeting} users={users} backoffice={backoffice} shared={shared} setReload={setReload}/>
                      </Table.Cell>
                    </Table.Row>
                  );
                }) }
              </>
            ) : (
              <>
                { meetings ? (
                  <>
                    { meetings.map(meeting => {
                      return (
                        <Table.Row key={meeting.id} className={ 
                          meeting.InTime === EEventInTime.LaterAccess ? "later_access" : meeting.InTime === EEventInTime.EarlyAccess ? "early_access" : "in_time" 
                        }>
                          <Table.Cell>
                            { moment(meeting.Start).format("HH:mm") }
                          </Table.Cell>
                          <Table.Cell>
                            <AreaAdministrationServiceDetailsMeetingsAttendee code={meeting.Code} comment={meeting.Observations} attendees={meeting.Attendees} subjects1={meeting.Subject1} subjects2={meeting.Subject2} subjects3={meeting.Subject3} />
                          </Table.Cell>
                          <Table.Cell>
                            { meeting.Status ? t(`detail.status.${meeting.Status.toLowerCase()}`) : t("detail.status.notattendeed") }
                          </Table.Cell>
                          <Table.Cell>
                            <AreaAdministrationServiceDetailsMeetingsActions meeting={meeting} users={users} backoffice={backoffice} shared={shared} setReload={setReload}/>
                          </Table.Cell>
                        </Table.Row>
                      );
                    }) }
                  </>
                ) : (
                  <div style={{ margin: '10px' }}> {t("detail.meeting.no_meeting_found")} </div>
                )}
              </>
            )}
          </Table.Body>
        </Table>
      </>
    </>
  );
}

export default AreaAdministrationServiceDetailsMeetings;
